import React, {
  ChangeEvent,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/button';
import FullHomeAssistant from '../../../assets/home-assistant-full.jpg';
import HomeAssistant from '../../../assets/home-assistant.jpg';
import { useScreenSize, WidthType } from '../../../hooks/use-is-mobile';
import style from './style.module.less';
import { AskInputComponent } from './ask-input';

export const HomeHero: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screenType = useScreenSize();
  const [question, setQuestion] = useState('');

  // we want to navigate to the case using this variable to control after deleteSession is called

  const onCaseClick = useCallback(
    (description?: string | null) => {
      navigate('/chat', {
        state: { newCase: true, message: description ?? undefined },
      });
    },
    [navigate]
  );

  const onChangeQuestion = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setQuestion(e.target.value);
    },
    []
  );

  const submitQuestion = useCallback(() => {
    if (question.length) {
      onCaseClick(question);
    } else {
      onCaseClick();
    }
  }, [onCaseClick, question]);

  const handleEnter = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        submitQuestion();
      }
    },
    [submitQuestion]
  );
  const isDesktop =
    screenType === WidthType.LargeDesktop ||
    screenType === WidthType.SmallDesktop;

  const Image =
    screenType === WidthType.Mobile ? HomeAssistant : FullHomeAssistant;

  return (
    <>
      <div className={style.heroCard}>
        <div className={style.titleWrapper}>
          <div className={style.title}>{t('home.title1')}</div>
        </div>
        <div className={style.heroImageWrapper}>
          <img src={Image} alt='scale' className={style.heroImage} />
          <div className={style.background} />
        </div>
        {isDesktop && (
          <AskInputComponent
            question={question}
            onChangeQuestion={onChangeQuestion}
            submitQuestion={submitQuestion}
            handleEnter={handleEnter}
          />
        )}
      </div>

      {!isDesktop && (
        <AskInputComponent
          question={question}
          onChangeQuestion={onChangeQuestion}
          submitQuestion={submitQuestion}
          handleEnter={handleEnter}
        />
      )}
    </>
  );
};
