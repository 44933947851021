import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import {
  NarrowWrapper,
  WrapperOffsetSide,
} from '../../components/narrow-wrapper';
import { Link } from 'react-router-dom';
import style from './style.module.less';
import { PageTitleHero } from '../../components/page-title-hero';
import { ContentParagraphs } from '../../components/content-paragraphs';
import { INFO_LINK } from '../../constants';
import { Helmet } from 'react-helmet';

interface Props {}
export const Privacy: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('pages.privacy-policy')}</title>
      </Helmet>
      <Header />
      <PageTitleHero title={t('privacy.title')} />
      <NarrowWrapper
        offset={WrapperOffsetSide.LEFT}
        offsetClassName={style.offsetContainer}
      >
        <div className={style.content}>
          <div className={style.section}>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.description.0')}
                <br />
                {t('privacy.description.1')}
              </p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.description.2')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h1>{t('privacy.subtitle')}</h1>
            <h2>1. {t('privacy.1.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.1.body.0')}
                <ol>
                  <li>{t('privacy.1.body.1')}</li>
                  <li>{t('privacy.1.body.2')}</li>
                  <li>{t('privacy.1.body.3')}</li>
                </ol>
              </p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>2. {t('privacy.2.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.2.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>3. {t('privacy.3.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.3.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>4. {t('privacy.4.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.4.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>5. {t('privacy.5.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                <ul>
                  <li>
                    <b>{t('privacy.5.list.0.label')}</b>
                    {' '}{t('privacy.5.list.0.text')}
                  </li>
                  <li>
                    <b>{t('privacy.5.list.1.label')}</b>
                    {' '}{t('privacy.5.list.1.text')}
                  </li>
                  <li>
                    <b>{t('privacy.5.list.2.label')}</b>
                    {' '}{t('privacy.5.list.2.text')}
                  </li>
                </ul>
              </p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>6. {t('privacy.6.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.6.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>7. {t('privacy.7.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.7.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>8. {t('privacy.8.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.8.body')}</p>
            </ContentParagraphs>
          </div>
          <div className={style.section}>
            <h2>9. {t('privacy.9.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('privacy.9.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>{t('privacy.contact.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('privacy.contact.body')}{' '}
                <Link to={`mailto:${INFO_LINK}`}>{INFO_LINK}</Link>
              </p>
            </ContentParagraphs>
          </div>
        </div>
      </NarrowWrapper>
      <Footer />
    </>
  );
};
