import React from 'react';
import { LegalCaseState, Prematter } from '@law-connect/types';
import FormComponent from '../../containers/chat/form';
import CompletedSummaryComponent from '../../containers/chat/complete';
import { getVariantFromPrematter } from '../../utils/get-variant';
import { canUseSettify } from '../../utils/can-use-settify';
import { isPrematterComplete } from '../../utils/is-prematter-complete';

interface Props {
  prematter: Prematter;
  pending?: boolean;
  readOnly?: boolean;
  addQuestionError?: string | null;
  addQuestion?: (args: { question: string }) => void;
  deleteQuestion?: (args: { questionId: string }) => void;
  onConfirm?: () => void;
}

export const ChatStateBody: React.FC<Props> = (props) => {
  const { prematter, readOnly } = props;

  const settifyFlow = canUseSettify(prematter);
  const useInlineExtendedForm = getVariantFromPrematter(prematter) === 'A';

  if (!prematter) {
    return null;
  }

  if (
    prematter.state === LegalCaseState.Form ||
    (prematter.state === LegalCaseState.ExtendedForm &&
      settifyFlow &&
      useInlineExtendedForm)
  ) {
    return <FormComponent prematter={prematter} readOnly={readOnly} />;
  } else if (
    prematter.state === LegalCaseState.ExtendedForm ||
    prematter.state === LegalCaseState.AuthCheck ||
    isPrematterComplete(prematter.state)
  ) {
    return (
      <>
        <FormComponent prematter={prematter} readOnly={readOnly} />
        <CompletedSummaryComponent readOnly={readOnly} prematter={prematter} />
      </>
    );
  } else {
    return null;
  }
};
