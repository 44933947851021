import React, { FC, useEffect } from 'react';
import { LawyerConnection } from '@law-connect/types';
import style from './modal.module.less';
import CrossCircleIcon from '../../../assets/icons/cross-circle.svg?react';
import Markdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import placeholderImage from '../../../assets/profile-no-img.png';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  connection: LawyerConnection;
}

export const LawyerIntroModal: FC<Props> = ({
  isOpen,
  closeModal,
  connection,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    // stop scrolling when modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  if (!connection) {
    return null;
  }

  return (
    <>
      {isOpen && (
        <div className={style.fullScreenModal}>
          <div className={style.wrapper}>
            <div className={style.headerModal}>
              <div className={style.lawyerName}>
                {connection.lawyer.firstName}
                {connection.lawyer.firstName && connection.lawyer.lastName
                  ? ' '
                  : ''}
                {connection.lawyer.lastName}
              </div>
            </div>
            <div className={style.closeIconContainer} onClick={closeModal}>
              <CrossCircleIcon className={style.closeIcon} />
            </div>
            <div className={style.bodyContainer}>
              <div className={style.lawyerInfoCard}>
                <img
                  src={connection.lawyer.image}
                  alt='lawyer avatar'
                  className={style.lawyerImage}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = placeholderImage;
                  }}        
                />
                <div className={style.lawyerDetails}>
                  <div className={style.lawyerName}>
                    {connection.lawyer.firstName}
                    {connection.lawyer.firstName && connection.lawyer.lastName
                      ? ' '
                      : ''}
                    {connection.lawyer.lastName}
                  </div>
                  {connection.lawyer.firmName ? (
                    <div className={style.firmName}>
                      {connection.lawyer.firmName}
                    </div>
                  ) : null}
                  {/* {connection.lawyer.email ? (
                    <a
                      href={`mailto:${connection.lawyer.email}`}
                      className={style.lawyerEmail}
                    >
                      {connection.lawyer.email}
                    </a>
                  ) : null} */}
                  {connection?.lawyer?.website && (
                    <a
                      href={connection.lawyer.website}
                      className={style.lawyerEmail}
                    >
                      {connection.lawyer.website}
                    </a>
                  )}
                  {connection.lawyer.phoneNumber ? (
                    <div className={style.phoneContainer}>
                      <span>Phone:&nbsp;</span>
                      <a
                        href={`tel:${connection.lawyer.phoneNumber}`}
                        className={style.lawyerEmail}
                      >
                        {connection.lawyer.phoneNumber}
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={style.messageContainer}>
                <Markdown className={style.message}>
                  {connection.lawyer.presentation}
                </Markdown>
                <div className={style.closeContainer} onClick={closeModal}>
                  <CrossCircleIcon className={style.bottomCloseIcon} />
                  <div className={style.closeText}>{t('confirm.close')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
