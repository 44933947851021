import React, { forwardRef, useEffect, useRef } from 'react';
import styles from './style.module.less';
import WarningCircleIcon from '../../assets/icons/warning-circle.svg?react';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  resetSession: () => void;
  refreshSession: () => void;
  disableChat: () => void;
}

export interface SessionExpiryWarningRef {
  ref: React.RefObject<HTMLDivElement>;
  resetTimeLeft: () => void;
}

const sessionExpiryTimeInSeconds = 30 * 60; // 30 minutes
const warningTimeInSeconds = 5 * 60; // 5 minutes

export const SessionExpiryWarning = forwardRef<SessionExpiryWarningRef, Props>(
  (props, ref) => {
    const { className, style, resetSession, refreshSession, disableChat } =
      props;

    const divRef = useRef<HTMLDivElement>(null);
    const [lastActivity, setLastActivity] = React.useState(Date.now());
    const [timeLeft, setTimeLeft] = React.useState(sessionExpiryTimeInSeconds);

    const resetState = () => {
      setLastActivity(Date.now());
      setTimeLeft(sessionExpiryTimeInSeconds);
      resetSession();
    };

    const refreshState = () => {
      setLastActivity(Date.now());
      setTimeLeft(sessionExpiryTimeInSeconds);
      refreshSession();
    };

    const resetTimeLeft = () => {
      setLastActivity(Date.now());
      setTimeLeft(sessionExpiryTimeInSeconds);
    };

    useEffect(() => {
      const updateCountdown = () => {
        const now = Date.now();
        const elapsedTime = Math.floor((now - lastActivity) / 1000);
        const updatedTimeLeft = sessionExpiryTimeInSeconds - elapsedTime;

        // Ensure the remaining time does not go below zero
        if (updatedTimeLeft <= 0) {
          setTimeLeft(0);
        } else {
          setTimeLeft(updatedTimeLeft);
        }
      };

      updateCountdown(); // Initial call to set the time left immediately
      const timerId = setInterval(updateCountdown, 1000);

      return () => clearInterval(timerId); // Clean up the interval if the component unmounts
    }, [lastActivity]);

    useEffect(() => {
      if (timeLeft <= 0) {
        disableChat();
      }
    }, [timeLeft, disableChat]);

    // pass the refresh and reset functions to the parent component
    React.useImperativeHandle(ref, () => ({
      resetTimeLeft,
      ref: divRef,
    }));

    const minutes = Math.floor(timeLeft / 60);
    const seconds = Math.floor(timeLeft % 60);
    let content = null;

    if (timeLeft <= 0) {
      content = (
        <div className={styles.notification}>
          <WarningCircleIcon />
          <div className={styles.text}>
            <div className={styles.expired}>
              Your chat session has expired.{' '}
            </div>
            <div>
              <span onClick={resetState}>Reset</span>.
            </div>
          </div>
        </div>
      );
    } else if (timeLeft <= warningTimeInSeconds) {
      content = (
        <div className={styles.notification}>
          <WarningCircleIcon />
          <div className={styles.text}>
            <div className={styles.countdown}>
              Session will expire in{' '}
              <span
                style={{
                  width: `${minutes.toString().length * 13 + 16}px`,
                }}
              >
                {minutes} m
              </span>{' '}
              <span
                style={{
                  width: `${minutes.toString().length * 13 + 16}px`,
                }}
              >
                {seconds} s
              </span>
            </div>
            <div>
              <span onClick={refreshState}>Renew Now</span>.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`${styles.sessionExpiryWrapper} ${className}`}
        style={style}
      >
        <div ref={divRef} className={styles.sessionExpiry}>
          {content}
        </div>
      </div>
    );
  }
);
