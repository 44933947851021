/* eslint-disable @stylistic/js/indent */
import { MessageType, Question } from '@law-connect/types';
import { RootState } from './store';
const MAX_RECENT_CASES = 6;

const selectors = {
  session: {
    getSessionId: () => (state: RootState) => state.session.sessionId,
    isFetchSessionPending: () => (state: RootState) =>
      state.session.pending.fetch,
    isDeleteSessionPending: () => (state: RootState) =>
      state.session.pending.delete,
    isWaitingForServer: () => (state: RootState) =>
      state.session.waitingForServer,
    isGetFormPending: () => (state: RootState) => state.session.pending.getForm,
    isSendFormPending: () => (state: RootState) =>
      state.session.pending.sendForm,
    isLocationPending: () => (state: RootState) =>
      state.session.pending.location,
    isAddQuestionPending: () => (state: RootState) =>
      state.session.pending.addQuestion,
    isDeleteQuestionPending: () => (state: RootState) =>
      state.session.pending.deleteQuestion,
    preConfirmationPending: () => (state: RootState) =>
      state.session.waitingForServer ||
      state.session.pending.getForm ||
      state.session.pending.location ||
      state.session.pending.fetch,

    getGeneralError: () => (state: RootState) => state.session.errors.general,

    getPrematter: () => (state: RootState) => state.session.prematter,
    getPrematterId: () => (state: RootState) => state.session.prematter?.id,
    getChatState: () => (state: RootState) => state.session.prematter?.state,
    getMessages: () => (state: RootState) =>
      state.session.prematter?.messages
        ? state.session.prematter?.messages.filter(
            (m) =>
              m.type === MessageType.Chat ||
              m.type === MessageType.Location ||
              m.type === MessageType.Confirmation
          )
        : [],
    getFaq: () => (state: RootState) =>
      state.session.prematter?.context?.questions ?? [],
    getForm:
      () =>
      (state: RootState): Question[] =>
        state.session.prematter?.context?.form?.questions ?? [],
    // errors
    fetchError: () => (state: RootState) => state.session.errors.fetch,
    getFormError: () => (state: RootState) => state.session.errors.getForm,
    deleteQuestionError: () => (state: RootState) =>
      state.session.errors.deleteQuestion,
    addQuestionError: () => (state: RootState) =>
      state.session.errors.addQuestion,
    sendFormError: () => (state: RootState) => state.session.errors.sendForm,
    getAddQuestionError: () => (state: RootState) =>
      state.session.errors.addQuestion,
    getChatError: () => (state: RootState) => {
      return (
        state.session.errors.fetch ||
        state.session.errors.getForm ||
        state.session.errors.sendForm
      );
    },
    isSessionPending: () => (state: RootState) => {
      return (
        state.session.waitingForServer ||
        state.session.pending.fetch ||
        state.session.pending.getForm ||
        // state.session.pending.sendForm ||
        state.session.pending.location
      );
    },
    getTempMessage: () => (state: RootState) => state.session.tempMessage,

    isAwaitingReply: () => (state: RootState) =>
      state.session.pending.sendMessage,
    isFileUploading: () => (state: RootState) => state.session.pending.upload,
  },
  websocket: {
    isSendingMessage: () => (state: RootState) =>
      state.websocket.pending.sendMessage,
    isConnecting: () => (state: RootState) => state.websocket.pending.connect,
    isDeletingSession: () => (state: RootState) =>
      state.websocket.pending.deleteSession,
    isSetUp: () => (state: RootState) => state.websocket.isSetUp,
    sendMessageError: () => (state: RootState) =>
      state.websocket.errors.sendMessage,
    connectError: () => (state: RootState) => state.websocket.errors.connect,
  },
  prematter: {
    getAll: () => (state: RootState) => state.prematter.prematters || [],
    getRecent: () => (state: RootState) => {
      return [...state.prematter.prematters]
        .sort((a, b) => b.createdAt - a.createdAt)
        .slice(0, MAX_RECENT_CASES);
    },
    getById: (id: string) => (state: RootState) =>
      state.prematter.prematters?.find((prematter) => prematter.id === id),
    isFetchPending: () => (state: RootState) => state.prematter.pending.fetch,
    isFetchByIdPending: () => (state: RootState) =>
      state.prematter.pending.fetchById,
    getAddQuestionError: (id: string) => (state: RootState) =>
      state.prematter.errors.addQuestion[id],
    isAddQuestionPending: (id: string) => (state: RootState) =>
      state.prematter.pending.addQuestion[id],
    getFetchError: () => (state: RootState) => state.prematter.errors.fetch,
    getFetchByIdError: () => (state: RootState) =>
      state.prematter.errors.fetchById,
    isUpdatePending: () => (state: RootState) => state.prematter.pending.update,
    isDeletePending: () => (state: RootState) => state.prematter.pending.delete,
    getDeleteError: () => (state: RootState) => state.prematter.errors.delete,

    getFiles: (id: string) => (state: RootState) => state.prematter.files[id],
    isVerifyCheckPending: () => (state: RootState) =>
      state.prematter.pending.canVerify,
    getVerifyCheckError: () => (state: RootState) =>
      state.prematter.errors.canVerify,
    canBeVerified: (id: string) => (state: RootState) =>
      state.prematter.canVerify[id],
    isSendVerifyPending: () => (state: RootState) =>
      state.prematter.pending.sendVerify,
    getSendVerifyError: () => (state: RootState) =>
      state.prematter.errors.sendVerify,
  },
  file: {
    getAll: () => (state: RootState) => state.file.files,
    isFetchByPrematterIdPending: (prematterId: string) => (state: RootState) =>
      state.file.pending.fetchByPrematterId[prematterId] || false,
    hasFetchByPrematterIdError: (prematterId: string) => (state: RootState) =>
      state.file.errors.fetchByPrematterId[prematterId],
    isUploadPending: () => (state: RootState) => state.file.pending.upload,
    getTempFiles: () => (state: RootState) => state.file.uploadedTemp,
    getUploadError: () => (state: RootState) => state.file.errors.upload,
    getPrematterFiles: (prematterId: string) => (state: RootState) =>
      state.file.files.filter((f) => f?.prematterIds?.includes(prematterId)) ??
      [],
  },
  user: {
    isDeleting: () => (state: RootState) => state.user.pending.delete,
    getDeleteError: () => (state: RootState) => state.user.errors.delete,
    getUser: () => (state: RootState) => state.user.data,
    isFetchingUser: () => (state: RootState) => state.user.pending.fetch,
    isUpdating: () => (state: RootState) => state.user.pending.update,
    getUpdateError: () => (state: RootState) => state.user.errors.update,
  },
  lawyerConnection: {
    isFetchingByPrematterId: () => (state: RootState) =>
      state.lawyerConnection.pending.fetchByPrematterId,
    getFetchByPrematterIdError: () => (state: RootState) =>
      state.lawyerConnection.errors.fetchByPrematterId,
    getConnectionById: (id: string) => (state: RootState) =>
      state.lawyerConnection.connections[id]?.connection,
    getConnections: () => (state: RootState) =>
      Object.values(state.lawyerConnection.connections).map(
        (c) => c.connection
      ),
    getConnectionsByPrematterId: (prematterId: string) => (state: RootState) =>
      Object.values(state.lawyerConnection.connections)
        .filter((c) => c.connection.prematterId === prematterId)
        .map((c) => c.connection),
    getUnreadConnections: (prematterId: string) => (state: RootState) => {
      return Object.values(state.lawyerConnection.connections || {}).filter(
        (connection) =>
          connection.connection.readAt === null &&
          connection.connection.prematterId === prematterId
      );
      // return Object.values(state.lawyerConnection.connections || {}).filter(
      //   (connection) => {
      //     if (connection.connection.prematterId !== prematterId) {
      //       return false;
      //     }
      //     // Check last message from lawyer
      //     const lastMessage =
      //       connection.messages[connection.messages.length - 1];
      //     if (lastMessage?.from === 'lawyer') {
      //       if (!lastMessage.readAt) {
      //         return true;
      //       }
      //     }
      //     return false;
      //   }
      // );
    },
    getMessagesById: (id: string) => (state: RootState) =>
      state.lawyerConnection.connections[id]?.messages,
    isSendingMessage: () => (state: RootState) =>
      state.lawyerConnection.pending.sendMessage,
    getSendMessageError: () => (state: RootState) =>
      state.lawyerConnection.errors.sendMessage,
    isFetchingMessages: () => (state: RootState) =>
      state.lawyerConnection.pending.fetchMessages,
    getFetchMessagesError: () => (state: RootState) =>
      state.lawyerConnection.errors.fetchMessages,
    getClientIntakeForm: (connectionId: string) => (state: RootState) =>
      state.lawyerConnection.connections[connectionId]?.clientIntakeForm,
    isClientIntakeFormUpdating: () => (state: RootState) =>
      state.lawyerConnection.pending.updateClientIntakeForm,
    getFiles: (connectionId: string) => (state: RootState) =>
      state.lawyerConnection.connections[connectionId]?.files ?? [],
    isFetchingFiles: () => (state: RootState) =>
      state.lawyerConnection.pending.fetchFiles,
    isSendingFiles: () => (state: RootState) =>
      state.lawyerConnection.pending.sendFiles,
    isPollingConnections: () => (state: RootState) =>
      state.lawyerConnection.pending.pollConnections,
  },
};

export default selectors;
