import React, { FC, useRef } from 'react';
import { LawyerConnection, Message, MessageType } from '@law-connect/types';
import {
  ChatMessageType,
  ChatMessageWrapper,
  formatLongDateTime,
} from '@law-connect/react-components';
import WarningCircleIcon from '../../assets/icons/warning-circle.svg?react';
import TickCircleIcon from '../../assets/icons/tick-circle.svg?react';
import CrossCircleIcon from '../../assets/icons/cross-circle.svg?react';
import style from './style.module.less';
import { Button } from '../button';
import { PermissionModal, PermissionModalRef } from '../permission-modal';
import { useTranslation } from 'react-i18next';

interface Props {
  connection: LawyerConnection;
  messages: Message[];
  message: Message;
}

export const PermissionMessage: FC<Props> = (props) => {
  const { connection, message, messages } = props;
  const modalRef = useRef<PermissionModalRef | null>(null);
  const { t } = useTranslation();
  if (message.type === MessageType.Permission) {
    if (message.from === 'lawyer') {
      // if from the lawyer, if still pending we want to append the action to respond, else we hide
      const closingPermissionMessage = messages?.find(
        (m) =>
          m.type === MessageType.Permission &&
          m.from === 'client' &&
          m.content.requestMessageId === message.id
      );
      const state = !closingPermissionMessage ? (
        'Allow access?'
      ) : closingPermissionMessage.type === MessageType.Permission &&
        closingPermissionMessage.content?.permissions?.userDetails ? (
          <>
            <b>Granted</b>.
          </>
        ) : (
          <>
            <b>Denied</b>.
          </>
        );
      const lawyerName = connection?.lawyer?.firstName ?? 'Your lawyer';

      return (
        <>
          <ChatMessageWrapper
            type={ChatMessageType.Receiver}
            timestamp={formatLongDateTime(message.createdAt)}
            externalBody={
              <>
                {!closingPermissionMessage ? (
                  <div className={style.respondButtonContainer}>
                    <Button
                      label={t('chat.respond')}
                      onClick={() => modalRef.current?.openModal()}
                      className={style.respondButton}
                      labelClassName={style.respondButtonLabel}
                      disabled={
                        connection.endedAt || connection.deletedAt
                          ? true
                          : false
                      }
                    />
                  </div>
                ) : null}
              </>
            }
            messageBody={
              <>
                <div className={style.iconContainer}>
                  <WarningCircleIcon className={style.icon} />
                </div>
                {lawyerName} has requested to view private information linked to
                this case.&nbsp;{state}
              </>
            }
          />
          <PermissionModal ref={modalRef} requestMessageId={message.id} />
        </>
      );
    } else {
      const permissionsAllowed = message.content?.permissions?.userDetails;
      // permission response from client
      return (
        <>
          <ChatMessageWrapper
            type={ChatMessageType.Sender}
            timestamp={formatLongDateTime(message.createdAt)}
            messageBody={
              <>
                <div className={style.iconContainer}>
                  {permissionsAllowed ? (
                    <TickCircleIcon className={style.tickIcon} />
                  ) : (
                    <CrossCircleIcon className={style.crossCircleIcon} />
                  )}
                </div>
                Access to private information
                <b>{permissionsAllowed ? ' granted' : ' denied'}</b>.
              </>
            }
          />
        </>
      );
    }
  }
};
