import React, { FC } from 'react';
import { LawyerConnection, Message, MessageType } from '@law-connect/types';
import {
  ChatMessageType,
  ChatMessageWrapper,
  formatLongDateTime,
} from '@law-connect/react-components';
import WarningCircleIcon from '../../assets/icons/warning-circle.svg?react';
import TickCircleIcon from '../../assets/icons/tick-circle.svg?react';
import style from './style.module.less';
import { Button } from '../button';

interface Props {
  connection: LawyerConnection;
  messages: Message[];
  message: Message;
}

export const ClientInductionMessage: FC<Props> = (props) => {
  const { connection, message, messages } = props;

  const openClientInductionForm = () => {
    // Create an a href to open the client induction form in a new tab
    const link = document.createElement('a');
    // eslint-disable-next-line @stylistic/js/max-len
    link.href = `${window.location.origin}/lawyer-connection/${connection?.id}/client-intake-form`;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  };

  if (message.type === MessageType.ClientIntakeForm) {
    if (message.from === 'lawyer') {
      // if from the lawyer, if still pending we want to append the action to respond, else we hide
      const clientIntakenMessage = messages?.find(
        (m) => m.type === MessageType.ClientIntakeForm && m.from === 'client'
      );
      const lawyerName = connection?.lawyer?.firstName ?? 'Your lawyer';

      return (
        <>
          <ChatMessageWrapper
            type={ChatMessageType.Receiver}
            timestamp={formatLongDateTime(message.createdAt)}
            externalBody={
              <>
                {!clientIntakenMessage ? (
                  <div className={style.respondButtonContainer}>
                    <Button
                      label={'Open'}
                      onClick={openClientInductionForm}
                      className={style.respondButton}
                      disabled={
                        connection.endedAt || connection.deletedAt
                          ? true
                          : false
                      }
                    />
                  </div>
                ) : null}
              </>
            }
            messageBody={
              <>
                <div className={style.iconContainer}>
                  <WarningCircleIcon className={style.icon} />
                </div>
                {lawyerName} has sent you a client induction form.
              </>
            }
          />
        </>
      );
    } else {
      // permission response from client
      return (
        <>
          <ChatMessageWrapper
            type={ChatMessageType.Sender}
            timestamp={formatLongDateTime(message.createdAt)}
            messageBody={
              <>
                <div className={style.iconContainer}>
                  <TickCircleIcon className={style.tickIcon} />
                </div>
                You completed induction form.
              </>
            }
          />
        </>
      );
    }
  }
};
