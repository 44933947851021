import React from 'react';
import { Message, MessageType, File } from '@law-connect/types';
import styles from './style.module.less';
import { FilePreview } from '../../../../components/chat-files/file';
import nl2br from 'react-br';

// const FileRender = (file: File) => {
//   return (
//     <div className={styles.fileWrapper}>
//       <div className={styles.fileIcon}>
//         <img src={file.url} alt={file.name} />
//       </div>
//       <div className={styles.fileName}>{file.name}</div>
//     </div>
//   );
// }

interface Props {
  type: 'sender' | 'receiver';
  message: Message;
  children?: React.ReactNode;
}

export const MessageRenderer: React.FC<Props> = (props) => {
  const { message, children } = props;

  let content: React.ReactNode = null;

  // Chat = "chat",
  // Location = "location",
  // Confirmation = "confirmation",
  // Permission = "permission",
  // ClientIntakeForm = "client-intake-form"

  switch (message.type) {
    case MessageType.Chat:
    case MessageType.Location:
    case MessageType.Confirmation:
      content =
        message.content.length > 0 ? nl2br(message.content.toString()) : null;
      break;
    default: {
      content = null;
    }
  }

  return (
    <div className={`${styles.messageWrapper} ${styles[props.type]}`}>
      {content ? (
        <div className={`${styles.message} ${styles[props.type]}`}>
          {content}
        </div>
      ) : null}
      {children}
      <div className={`${styles.messageTime}`}>
        {new Date(message.createdAt || Date.now()).toLocaleString(undefined, {
          weekday: 'short', // 'Mon'
          day: '2-digit', // '18'
          month: 'short', // 'Mar'
          year: '2-digit', // '24'
          hour: '2-digit', // '11'
          minute: '2-digit', // '45'
          hour12: true, // 'am'/'pm'
        })}
      </div>
    </div>
  );
};
