import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import env from '../../../constants/env';
import ChatIcon from '../../../assets/icons/chat.svg?react';
import FileIcon from '../../../assets/icons/file.svg?react';
import ScaleIcon from '../../../assets/icons/scale.svg?react';
import { toast } from 'react-toastify';

const EMAIL = `maxwell@${env.EMAIL_DOMAIN}`;
export const HomeDescriptionComponent: FC = () => {
  const { t } = useTranslation();
  const emailUrl = useMemo(() => {
    return `mailto:${EMAIL}`;
  }, []);

  const emailBody = useMemo(() => {
    const text = t('home.descriptions.engage.body');
    return text ? text.replace('EMAIL_LINK', emailUrl) : '';
  }, [emailUrl, t]);

  const navigateToEmail = useCallback(() => {
    window.open(emailUrl, '_blank');
  }, [emailUrl]);

  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(EMAIL);
    toast.success(t('chat.copied-link'));
  }, [t]);

  return (
    <>
      <div className={style.container}>
        <div className={style.grid}>
          {/* title and body seperated in order for multiline titles to align correctly */}
          {/* content and title in the same grid cause for mobile we need to do some magic */}
          <>
            <div className={`${style.titleContainer} ${style.set1}`}>
              <ChatIcon className={style.icon} />
              <div className={style.text}>
                {t('home.descriptions.engage.title')}
              </div>
            </div>
            <div className={`${style.titleContainer} ${style.set2}`}>
              <FileIcon className={`${style.icon} ${style.small}`} />
              <div className={style.text}>
                {t('home.descriptions.analyse.title')}
              </div>
            </div>
            <div className={`${style.titleContainer} ${style.set3}`}>
              <ScaleIcon className={style.icon} />
              <div className={style.text}>
                {t('home.descriptions.review.title')}
              </div>
            </div>
          </>
          <>
            <div className={`${style.body} ${style.set1}`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: emailBody,
                }}
              />
            </div>
            <div className={`${style.body} ${style.set2}`}>
              {t('home.descriptions.analyse.body')}
            </div>
            <div className={`${style.body} ${style.set3}`}>
              {t('home.descriptions.review.body')}
            </div>
          </>
        </div>
      </div>
      {/* <div className={style.card}>
        <img src={HomeEmailUs} className={style.emailUsImage} alt='Email us' />
        <div className={style.bodyContainer}>
          <div className={style.title}>{t('home.email-issues')}</div>
          <div className={style.body}>
            {t('home.email-issues-description')}
          </div>
          <div className={style.buttonContainer}>
            <Button
              label={t('home.send-email')}
              className={style.emailUsButton}
              onClick={navigateToEmail}
            />
            <Button
              label={t('home.copy-link')}
              className={style.emailUsButton}
              onClick={copyLink}
              icon={<CopyIcon className={style.copyIcon}/>}
            />
          </div>
        </div>
      </div> */}
    </>
  );
};
