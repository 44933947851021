import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Markdown from 'react-markdown';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../components/header';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import { PrematterHeader } from '../../../components/prematter-header';
import {
  ContentTitle,
  NoPaddingContentTitle,
} from '../../../components/content-title';
import {
  NarrowWrapper,
  WrapperOffsetSide,
} from '../../../components/narrow-wrapper';
import style from './style.module.less';
import { Footer } from '../../../components/footer';
import { QuestionCards } from '../../../components/question-card';
import { ReportSummary } from '../../../components/report-summary';
import CloseIcon from '../../../assets/icons/cross-circle.svg?react';
import { useCookies } from 'react-cookie';
import { PrintReportIcon } from './print';
import { AddQuestionComponent } from './add-question';
import BotImage from '../../../assets/home-assistant.jpg';
import { VerificationBanner } from '../verification-banner';
import { MAX_ALLOWED_QUESTION } from '../../../constants';
import { NextStepsList } from './next-steps';
import { isVerifyDisabled } from '../../../utils/is-verify-disabled';
import {
  NextStepCard,
  NextStepCards,
} from '../../../components/next-step-card';
import { LegalCaseState } from '@law-connect/types';
import { useMobile } from '../../../hooks/use-is-mobile';
import { VerifyModalComponent } from '../../../components/verify-modal';
import { actions } from '../../../redux/slices';
import ShieldTickIcon from '../../../assets/icons/shield-tick.svg?react';

interface Props {}
export const PrematterReport: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const isMobile = useMobile();

  const [showModal, setShowModal] = useState(false);

  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>(null);
  // we need this mini hack because the state doesnt rerender on ref set so we need a variable change to rerender
  const [cookies, setCookie, removeCookie] = useCookies([
    'more-info-tooltip-hidden',
  ]);
  const [moreInfoTooltipHidden, setMoreInfoTooltipHidden] = React.useState(
    cookies['more-info-tooltip-hidden'] !== undefined
  );
  const prematter = useAppSelector(selectors.prematter.getById(id));
  const canBeVerified = useAppSelector(selectors.prematter.canBeVerified(id));
  const connections = useAppSelector(
    selectors.lawyerConnection.getConnectionsByPrematterId(id)
  );

  const [prematterQuestions, setPrematterQuestions] = useState(
    prematter.context.questions ?? []
  );

  const hasReplied = connections?.some(
    (c) => c.messages?.filter((m) => m.from === 'client').length > 0
  );
  const { t } = useTranslation(undefined, { lng: prematter?.language });
  const navigate = useNavigate();

  const verifiedLabel = useMemo(() => {
    if (prematter.state === LegalCaseState.ReportReady) {
      return t('verify.verify-now');
    } else if (prematter.state === LegalCaseState.VerificationPending) {
      return t('verify.pending');
    } else if (prematter.state === LegalCaseState.Verified) {
      return t('prematter.complete');
    } else {
      return null;
    }
  }, [prematter?.state, t]);

  const verifyAction = useCallback(() => {
    dispatch(actions.prematter.sendVerify({ id: prematter.id }));
    setShowModal(false);
  }, [dispatch, prematter?.id]);

  useEffect(() => {
    if (prematter?.context?.questions && !prematterQuestions.length) {
      setPrematterQuestions(prematter.context.questions);
    }
  }, [prematter, prematterQuestions]);

  if (!prematter) {
    return null;
  }

  // TODO: bug not sure why but when changing screens the ref is not set on mount
  return (
    <>
      <div
        ref={(r) => {
          ref.current = r;
        }}
        className={style.reportTopWrapper}
      >
        <Header />
        <PrematterHeader prematter={prematter} />
        <NarrowWrapper
          offset={WrapperOffsetSide.LEFT}
          backgroundClassName={style.overviewWrapper}
          offsetClassName={style.tabletWidth}
        >
          <VerificationBanner prematter={prematter} />
          <NoPaddingContentTitle
            title={t('prematter.overview')}
            className={style.title}
          >
            <PrintReportIcon prematterId={prematter.id} />
          </NoPaddingContentTitle>
          {/* <LightButton
            label={t('share')}
            icon={<ShareIcon />}
            onClick={() => {}}
          /> */}
          <Markdown className={style.description}>
            {prematter.description}
          </Markdown>
        </NarrowWrapper>

        <NarrowWrapper
          backgroundClassName={style.darkContent}
          offset={WrapperOffsetSide.FULL}
          offsetClassName={style.tabletWidth}
        >
          <div className={style.section}>
            <ContentTitle title={t('prematter.answers')}></ContentTitle>

            {moreInfoTooltipHidden ||
            isVerifyDisabled(prematter.state) ? null : (
                <div className={style.needMoreInfo}>
                  <div className={style.needMoreInfoTitle}>
                    {t('prematter.more-info-tooltip.title')}
                  </div>
                  <div
                    className={style.close}
                    onClick={() => {
                      setCookie('more-info-tooltip-hidden', 'true', {
                        path: '/',
                        maxAge: 60 * 60 * 24 * 365,
                      });
                      setMoreInfoTooltipHidden(true);
                    }}
                  >
                    {t('confirm.close')}
                    <CloseIcon className={style.closeIcon} />
                  </div>
                  <div className={style.youCanEdit}>
                    <span className={style.desktopTitle}>
                      {t('prematter.more-info-tooltip.title')}&nbsp;
                    </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('prematter.more-info-tooltip.explanation', {
                          interpolation: { escapeValue: false },
                        }),
                      }}
                      className={style.youCanEditText}
                    />
                  </div>
                </div>
              )}
            <QuestionCards
              questions={prematter.context.questions || []}
              prematterState={prematter.state}
            />
            {prematterQuestions?.length < MAX_ALLOWED_QUESTION ? (
              <>
                <AddQuestionComponent
                  id={prematter?.id}
                  prematterState={prematter?.state}
                  questionsLength={prematter?.context?.questions?.length}
                  language={prematter?.language}
                />
                {prematter.context?.questions?.length >=
                MAX_ALLOWED_QUESTION ? (
                    <div className={`${style.maxQuestion}`}>
                      {t('prematter.max-questions')}
                    </div>
                  ) : null}
              </>
            ) : null}
          </div>
        </NarrowWrapper>

        {canBeVerified ||
        prematter.state === LegalCaseState.VerificationPending ||
        (isVerifyDisabled(prematter.state) && !connections?.length) ? (
            <NarrowWrapper
              contentClassName={style.narrowContentWrapperForVerifyCard}
            >
              <NextStepCard
                className={style.verifyCard}
                image={{
                  src: `/next-steps/verify${isMobile ? '-mobile' : ''}.jpg`,
                  icon: <ShieldTickIcon width={24} height={24} />,
                  label: t('verify.verify'),
                }}
                title={t('verify.verify-case')}
                features={[
                  t('verify.verify-case-description.0'),
                  t('verify.verify-case-description.1'),
                  t('verify.verify-case-description.2'),
                ]}
                complete={prematter.state !== LegalCaseState.ReportReady}
                button={{
                  label: verifiedLabel,
                  onClick: () => setShowModal(true),
                }}
                rounded={true}
              />
            </NarrowWrapper>
          ) : connections?.length > 0 &&
          prematter.state === LegalCaseState.Verified ? (
              <NarrowWrapper
                contentClassName={style.narrowContentWrapperForVerifyCard}
              >
                <NextStepCard
                  className={style.verifyCard}
                  image={{
                    src: `/next-steps/connect${isMobile ? '-mobile' : ''}.jpg`,
                    icon: <ShieldTickIcon width={24} height={24} />,
                    label: t('connect.title'),
                  }}
                  title={t('connect.get-help')}
                  features={[
                    t('connect.get-help-description.0'),
                    t('connect.get-help-description.1'),
                    t('connect.get-help-description.2'),
                  ]}
                  complete={hasReplied}
                  button={{
                    label: !hasReplied
                      ? t('connect.title')
                      : t('prematter.complete'),
                    onClick: () => navigate(`/case/${prematter.id}/connect`),
                  }}
                  rounded={true}
                />
              </NarrowWrapper>
            ) : null}

        <div className={style.nextStepWrapper}>
          <NarrowWrapper
            offset={WrapperOffsetSide.RIGHT}
            large
            contentClassName={style.nextContentWrapper}
          >
            <div className={style.botImageContainer}>
              <div className={style.imageBorderWrapper}>
                <img src={BotImage} alt='bot' className={style.botImage} />
              </div>
            </div>
            <ReportSummary>{prematter.nextSteps}</ReportSummary>
          </NarrowWrapper>
        </div>
        <div className={style.disclaimer}>
          <div
            dangerouslySetInnerHTML={{
              __html: t('auth.disclaimer'),
            }}
          />
        </div>
        {canBeVerified || isVerifyDisabled(prematter.state) ? (
          <NextStepsList prematter={prematter} connections={connections} />
        ) : null}
        <Footer />
      </div>

      <VerifyModalComponent
        isOpen={showModal}
        close={() => setShowModal(false)}
        toVerify={true}
        verifyAction={verifyAction}
      />
    </>
  );
};
