import React, {
  useRef,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle,
  RefObject,
  useEffect,
} from 'react';
import style from './style.module.less';
import { File as AMFile, LawyerConnection, Message } from '@law-connect/types';
import useStayScrolled from 'react-stay-scrolled';
import { ChatInput, InputRef } from '@law-connect/react-components';
import { MessageWrapper } from './message-wrapper';
import { useTranslation } from 'react-i18next';
import { FileUploading } from '../chat-history/uploading-files';

export interface ChatElementProps extends React.HTMLAttributes<HTMLDivElement> {
  messages: Message[];
  files: AMFile[];
  onSendMessage: (args: { text?: string; files?: File[] }) => void;
  isLoading: boolean;
  inputClassName?: string;
  connection: LawyerConnection;
  isSendingFiles: boolean;
}

export interface ChatElementRef {
  inputRef: RefObject<HTMLDivElement> | null;
  chatRef: RefObject<HTMLDivElement> | null;
}

// todo: translate all
export const ChatElement = forwardRef<ChatElementRef, ChatElementProps>(
  (props, ref) => {
    const {
      onSendMessage,
      messages,
      isLoading,
      children,
      inputClassName,
      connection,
      files,
      isSendingFiles,
      ...elementProps
    } = props;

    const messagesRef = useRef(null);
    const { stayScrolled } = useStayScrolled(messagesRef);
    const inputRef = useRef<InputRef>(null);
    const chatRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    useLayoutEffect(() => {
      stayScrolled();
    }, [messages, stayScrolled]);

    useImperativeHandle(ref, () => ({
      inputRef: inputRef.current?.inputContainerRef ?? null,
      chatRef: chatRef,
    }));

    useEffect(() => {
      // we want to find the root component and add a on drag over event to it
      const root = document.getElementById('root');
      const onDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
      };

      const onDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const newFiles = e.dataTransfer.files;
        if (newFiles && inputRef.current) {
          inputRef.current.addFiles(Array.from(newFiles));
        }
      };
      if (root) {
        root?.addEventListener('dragover', onDragOver);
        root?.addEventListener('drop', onDrop);
        return () => {
          root?.removeEventListener('dragover', onDragOver);
          root?.removeEventListener('drop', onDrop);
        };
      }
    }, []);

    return (
      <div
        {...elementProps}
        className={`${style.chat} ${props.className || ''}`}
        ref={chatRef}
      >
        <div ref={messagesRef} className={style.messages}>
          {children}
          {messages.map((message, index) => {
            return (
              <MessageWrapper
                key={index}
                message={message}
                hoverDelay={200}
                connection={connection}
                messages={messages}
                files={files}
              />
            );
          })}

          <FileUploading filesLoading={isSendingFiles} />
          {isLoading && (
            <div className={`${style.message} ${style.lawyer}`}>typing..</div>
          )}
          {connection.endedAt || connection.deletedAt ? (
            <div className={`${style.conversationEnded}`}>
              {t('chat.conversation-ended')}
            </div>
          ) : null}
        </div>
        <ChatInput
          onSendMessage={onSendMessage}
          ref={inputRef}
          className={`${style.input} ${inputClassName}`}
          disabled={connection.endedAt || connection.deletedAt ? true : false}
        />
      </div>
    );
  }
);
