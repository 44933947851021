import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actions } from '.';
import {
  ClientIntakeForm,
  LawyerConnection,
  Message,
  File,
} from '@law-connect/types';

interface LawyerConnectionState {
  pending: {
    fetchAll: boolean;
    fetchById: boolean;
    fetchByPrematterId: boolean;
    fetchMessages: boolean;
    sendMessage: boolean;
    updateReadAt: boolean;
    fetchClientIntakeForm: boolean;
    updateClientIntakeForm?: boolean;
    markClientIntakeFormAsCompleted?: boolean;
    sendPermissionMessage: boolean;
    sendFiles: boolean;
    fetchFiles?: boolean;
    pollConnections?: boolean;
  };
  errors: {
    fetchAll?: string | null;
    fetchById?: string | null;
    fetchByPrematterId?: string | null;
    fetchMessages?: string | null;
    sendMessage?: string | null;
    updateReadAt?: string | null;
    fetchClientIntakeForm?: string | null;
    updateClientIntakeForm?: string | null;
    markClientIntakeFormAsCompleted?: string | null;
    sendPermissionMessage?: string | null;
    sendFiles?: string | null;
    fetchFiles?: string | null;
    pollConnections?: string | null;
  };
  connections: {
    // we have this structure so when updating the connection we dont overwrite the messages
    [id: string]: {
      connection: LawyerConnection;
      messages: Message[];
      clientIntakeForm?: ClientIntakeForm;
      files?: File[];
    };
  };
}

const lawyerConnectionState: LawyerConnectionState = {
  pending: {
    fetchAll: false,
    fetchById: false,
    fetchByPrematterId: false,
    fetchMessages: false,
    sendMessage: false,
    updateReadAt: false,
    fetchClientIntakeForm: false,
    updateClientIntakeForm: false,
    markClientIntakeFormAsCompleted: false,
    sendPermissionMessage: false,
    sendFiles: false,
    pollConnections: false,
  },
  errors: {},
  connections: {},
};

const lawyerConnectionSlice = createSlice({
  name: 'lawyerConnection',
  initialState: lawyerConnectionState,
  // you cant have a reducer name match the thunk action
  reducers: {
    pollMessages: (state, action) => {
      // do nothing and handled by middleware
    },
    stopPollMessages: (state, action) => {
      // do nothing and handled by middleware
    },
    addMessage: (
      state,
      action: PayloadAction<{ connectionId: string; message: Message }>
    ) => {
      if (state.connections[action.payload.connectionId]) {
        const message = state.connections[
          action.payload.connectionId
        ].messages.find((m) => m.id === action.payload.message.id);
        if (message) {
          // If message exists, we can update the readAt value
          message.readAt = action.payload.message.readAt;
        } else {
          state.connections[action.payload.connectionId].messages.push(
            action.payload.message
          );
        }
      }
    },
    startPollConnections: (state) => {

    },
    startPollConnectionsFinished: (state) => {
      state.pending.pollConnections = true;
    },
    stopPollConnections: (state) => {
      state.pending.pollConnections = false;
    },
  },
  extraReducers: (builder) => {
    //fetch all
    builder.addCase(actions.lawyerConnection.fetchAll.pending, (state) => {
      state.pending.fetchAll = true;
      state.errors.fetchAll = null;
    });
    builder.addCase(
      actions.lawyerConnection.fetchAll.fulfilled,
      (state, action) => {
        state.pending.fetchAll = false;
        state.errors.fetchAll = null;
        action.payload.forEach((item) => {
          if (!state.connections[item.id]) {
            state.connections[item.id] = {
              connection: item,
              messages: [],
            };
          } else {
            state.connections[item.id].connection = item;
          }
        });
      }
    );
    builder.addCase(
      actions.lawyerConnection.fetchAll.rejected,
      (state, action) => {
        state.pending.fetchAll = false;
        state.errors.fetchAll = action.error.message;
      }
    );
    // fetch single connection by id
    builder.addCase(actions.lawyerConnection.fetchById.pending, (state) => {
      state.errors.fetchById = null;
      state.pending.fetchById = true;
    });
    builder.addCase(
      actions.lawyerConnection.fetchById.fulfilled,
      (state, action) => {
        state.errors.fetchById = null;
        state.pending.fetchById = false;
        if (action.payload) {
          if (!state.connections[action.payload.id]) {
            state.connections[action.payload.id] = {
              connection: action.payload,
              messages: [],
            };
          } else {
            state.connections[action.payload.id].connection = action.payload;
          }
        }
      }
    );
    builder.addCase(
      actions.lawyerConnection.fetchById.rejected,
      (state, action) => {
        state.pending.fetchById = false;
        state.errors.fetchById = action.error.message;
      }
    );

    // fetch by prematter id
    builder.addCase(
      actions.lawyerConnection.fetchByPrematterId.pending,
      (state) => {
        state.pending.fetchByPrematterId = true;
        state.errors.fetchByPrematterId = null;
      }
    );
    builder.addCase(
      actions.lawyerConnection.fetchByPrematterId.fulfilled,
      (state, action) => {
        state.pending.fetchByPrematterId = false;
        state.errors.fetchByPrematterId = null;
        // we dont want duplicate ids so we will replace out the ones that are already in the state
        action.payload.forEach((item) => {
          if (!state.connections[item.id]) {
            state.connections[item.id] = {
              connection: item,
              messages: [],
            };
          } else {
            state.connections[item.id].connection = item;
          }
        });
      }
    );
    builder.addCase(
      actions.lawyerConnection.fetchByPrematterId.rejected,
      (state, action) => {
        state.pending.fetchByPrematterId = false;
        state.errors.fetchByPrematterId = action.error.message;
      }
    );
    // fetch messages
    builder.addCase(actions.lawyerConnection.fetchMessages.pending, (state) => {
      state.pending.fetchMessages = true;
      state.errors.fetchMessages = null;
    });
    builder.addCase(
      actions.lawyerConnection.fetchMessages.fulfilled,
      (state, action) => {
        state.pending.fetchMessages = false;
        state.errors.fetchMessages = null;
        if (!state.connections[action.meta.arg.id]) {
          state.connections[action.meta.arg.id] = {
            connection: null,
            messages: action.payload,
          };
        } else {
          state.connections[action.meta.arg.id].messages = action.payload;
        }
      }
    );
    builder.addCase(
      actions.lawyerConnection.fetchMessages.rejected,
      (state, action) => {
        state.pending.fetchMessages = false;
        state.errors.fetchMessages = action.error.message;
      }
    );
    // send message
    builder.addCase(actions.lawyerConnection.sendMessage.pending, (state) => {
      state.pending.sendMessage = true;
      state.errors.sendMessage = null;
    });
    builder.addCase(
      actions.lawyerConnection.sendMessage.fulfilled,
      (state, action) => {
        state.pending.sendMessage = false;
        state.errors.sendMessage = null;
        if (!state.connections[action.meta.arg.id]) {
          state.connections[action.meta.arg.id] = {
            connection: null,
            messages: [action.payload],
          };
        } else if (
          !state.connections[action.meta.arg.id].messages
            .map((m) => m.id)
            .includes(action.payload.id)
        ) {
          state.connections[action.meta.arg.id].messages.push(action.payload);
        }
      }
    );
    builder.addCase(
      actions.lawyerConnection.sendMessage.rejected,
      (state, action) => {
        state.pending.sendMessage = false;
        state.errors.sendMessage = action.error.message;
      }
    );
    // update read at
    builder.addCase(actions.lawyerConnection.updateReadAt.pending, (state) => {
      state.pending.updateReadAt = true;
      state.errors.updateReadAt = null;
    });
    builder.addCase(
      actions.lawyerConnection.updateReadAt.fulfilled,
      (state, action) => {
        state.pending.updateReadAt = false;
        state.errors.updateReadAt = null;
        if (state.connections[action.meta.arg.id]) {
          state.connections[action.meta.arg.id].connection.readAt =
            action.payload;
        }
      }
    );
    builder.addCase(
      actions.lawyerConnection.updateReadAt.rejected,
      (state, action) => {
        state.pending.updateReadAt = false;
        state.errors.updateReadAt = action.error.message;
      }
    );

    builder.addCase(
      actions.lawyerConnection.fetchClientIntakeForm.pending,
      (state) => {
        state.pending.fetchClientIntakeForm = true;
        state.errors.fetchClientIntakeForm = null;
      }
    );
    builder.addCase(
      actions.lawyerConnection.fetchClientIntakeForm.fulfilled,
      (state, action) => {
        state.pending.fetchClientIntakeForm = false;
        state.errors.fetchClientIntakeForm = null;
        if (!state.connections[action.meta.arg.connectionId]) {
          state.connections[action.meta.arg.connectionId] = {
            connection: null,
            messages: [],
          };
        }
        state.connections[action.meta.arg.connectionId].clientIntakeForm =
          action.payload;
      }
    );
    builder.addCase(
      actions.lawyerConnection.fetchClientIntakeForm.rejected,
      (state, action) => {
        state.pending.fetchClientIntakeForm = false;
        state.errors.fetchClientIntakeForm = action.error.message;
      }
    );

    builder.addCase(
      actions.lawyerConnection.updateClientIntakeForm.pending,
      (state) => {
        state.pending.fetchClientIntakeForm = true;
        state.errors.fetchClientIntakeForm = null;
      }
    );
    builder.addCase(
      actions.lawyerConnection.updateClientIntakeForm.fulfilled,
      (state, action) => {
        state.pending.fetchClientIntakeForm = false;
        state.errors.fetchClientIntakeForm = null;
        if (!state.connections[action.meta.arg.connectionId]) {
          state.connections[action.meta.arg.connectionId] = {
            connection: null,
            messages: [],
          };
        }
        state.connections[action.meta.arg.connectionId].clientIntakeForm =
          action.payload;
      }
    );
    builder.addCase(
      actions.lawyerConnection.updateClientIntakeForm.rejected,
      (state, action) => {
        state.pending.fetchClientIntakeForm = false;
        state.errors.fetchClientIntakeForm = action.error.message;
      }
    );

    builder.addCase(
      actions.lawyerConnection.markClientIntakeFormAsCompleted.pending,
      (state) => {
        state.pending.markClientIntakeFormAsCompleted = true;
        state.errors.markClientIntakeFormAsCompleted = null;
      }
    );
    builder.addCase(
      actions.lawyerConnection.markClientIntakeFormAsCompleted.fulfilled,
      (state, action) => {
        state.pending.markClientIntakeFormAsCompleted = false;
        state.errors.markClientIntakeFormAsCompleted = null;
        if (!state.connections[action.meta.arg.connectionId]) {
          state.connections[action.meta.arg.connectionId] = {
            connection: null,
            messages: [],
          };
        }
        state.connections[action.meta.arg.connectionId].clientIntakeForm = null;
      }
    );
    builder.addCase(
      actions.lawyerConnection.markClientIntakeFormAsCompleted.rejected,
      (state, action) => {
        state.pending.markClientIntakeFormAsCompleted = false;
        state.errors.markClientIntakeFormAsCompleted = action.error.message;
      }
    );
    //sendPermissionMessage
    builder.addCase(
      actions.lawyerConnection.sendPermissionMessage.pending,
      (state) => {
        state.pending.sendPermissionMessage = true;
        state.errors.sendPermissionMessage = null;
      }
    );
    builder.addCase(
      actions.lawyerConnection.sendPermissionMessage.fulfilled,
      (state, action) => {
        state.pending.sendPermissionMessage = false;
        state.errors.sendPermissionMessage = null;

        if (
          state.connections[action.meta.arg.id]?.connection &&
          state.connections[action.meta.arg.id]?.messages
        ) {
          if (
            !state.connections[action.meta.arg.id].messages
              .map((m) => m.id)
              .includes(action.payload.id)
          ) {
            state.connections[action.meta.arg.id].messages.push(action.payload);
          }
          state.connections[action.meta.arg.id].connection.permissions =
            action.meta.arg.permissions;
        }
      }
    );
    builder.addCase(
      actions.lawyerConnection.sendPermissionMessage.rejected,
      (state, action) => {
        state.pending.sendPermissionMessage = false;
        state.errors.sendPermissionMessage = action.error.message;
      }
    );

    builder.addCase(
      actions.lawyerConnection.markEnded.fulfilled,
      (state, action) => {
        state.connections[action.meta.arg.id].connection.endedAt = Date.now();
      }
    );
    builder.addCase(
      actions.lawyerConnection.markDeleted.fulfilled,
      (state, action) => {
        state.connections[action.meta.arg.id].connection.deletedAt = Date.now();
        if (state.connections[action.meta.arg.id]) {
          delete state.connections[action.meta.arg.id];
        }
      }
    );

    // send file
    builder.addCase(actions.lawyerConnection.sendFiles.pending, (state) => {
      state.pending.sendFiles = true;
      state.errors.sendFiles = null;
    });
    builder.addCase(
      actions.lawyerConnection.sendFiles.fulfilled,
      (state, action) => {
        state.pending.sendFiles = false;
        state.errors.sendFiles = null;
        if (!state.connections[action.meta.arg.id]) {
          state.connections[action.meta.arg.id] = {
            connection: null,
            messages: [action.payload],
          };
        } else if (
          !state.connections[action.meta.arg.id].messages
            .map((m) => m.id)
            .includes(action.payload.id)
        ) {
          state.connections[action.meta.arg.id].messages.push(action.payload);
        }
      }
    );
    builder.addCase(
      actions.lawyerConnection.sendFiles.rejected,
      (state, action) => {
        state.pending.sendFiles = false;
        state.errors.sendFiles = action.error.message;
      }
    );
    // fetch files 
    builder.addCase(actions.lawyerConnection.fetchFiles.pending, (state) => {
      state.pending.fetchFiles = true;
      state.errors.fetchFiles = null;
    });
    builder.addCase(
      actions.lawyerConnection.fetchFiles.fulfilled,
      (state, action) => {
        state.pending.fetchFiles = false;
        state.errors.fetchFiles = null;
        if (!state.connections[action.meta.arg.id]) {
          state.connections[action.meta.arg.id] = {
            connection: null,
            messages: [],
            files: action.payload,
          };
        } else {
          state.connections[action.meta.arg.id].files = action.payload;
        }
      }
    );
    builder.addCase(
      actions.lawyerConnection.fetchFiles.rejected,
      (state, action) => {
        state.pending.fetchFiles = false;
        state.errors.fetchFiles = action.error.message;
      }
    );
  },
});

// im not sure if this is good practice but it mimics what we used to do with dispatching actions
export const lawyerConnectionActions = lawyerConnectionSlice.actions;
export const lawyerConnectionReducer = lawyerConnectionSlice.reducer;
