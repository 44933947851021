import React, { useEffect } from 'react';
import style from './style.module.less';
import CloseIcon from '../../assets/icons/cross-circle.svg?react';
import CookieIcon from '../../assets/icons/cookie.svg?react';
import { Button } from '../button';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
export const COOKIE_ID = 'cookie-consent';
export const COOKIE_EVENT = 'cookie-consent-closed';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  onResult: (result: CookieConsentResult) => void;
  onClose: () => void;
}

export const CookieConsentComponent: React.FC<Props> = (props) => {
  const { onResult, onClose } = props;
  const { t } = useTranslation();

  return (
    <div className={style.cookieConsent} id={COOKIE_ID}>
      <div className={style.closeIconContainer} onClick={onClose}>
        <CloseIcon className={style.closeIcon}/>
      </div>
      <div className={style.content}>
        <div className={style.icon}>
          <CookieIcon />
          <div className={style.iconTitle}>Cookie Consent</div>
        </div>
        <div className={style.body}>
          <div
            className={style.description}
            dangerouslySetInnerHTML={{
              __html: t('auth.cookies.description'),
            }}
          />
          <div className={style.buttons}>
            <Button
              label={t('auth.cookies.allow-all')}
              onClick={() => onResult(CookieConsentResult.ACCEPT)}
              className={style.button}
              labelClassName={style.buttonLabel}
              iconClassName={style.buttonIcon}
            />
            {/* <Button
              label={t('auth.cookies.necessary')}
              onClick={() => onResult(CookieConsentResult.ACCEPT)}
              className={style.button}
              labelClassName={style.buttonLabel}
              iconClassName={style.buttonIcon}
            /> */}
            <Button
              label={t('auth.cookies.deny')}
              onClick={() => onResult(CookieConsentResult.ACCEPT)}
              className={`${style.button} ${style.deny}`}
              labelClassName={style.buttonLabel}
              iconClassName={style.buttonIcon}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const enum CookieConsentResult {
  ACCEPT = 'accept',
  ACCEPT_NECCESSARY = 'accept-necessary',
  REJECT = 'reject',
  NONE = 'none',
}

interface CookieConsentProps {
  targetId?: string;
  onResult: (result: CookieConsentResult) => void;
}

export const CookieConsent = (props: CookieConsentProps) => {
  const { onResult } = props;

  const [cookies, setCookie] = useCookies([COOKIE_ID]);
  const [outcome, setOutcome] = React.useState<CookieConsentResult>();

  useEffect(() => {
    if (cookies[COOKIE_ID]) {
      setOutcome(cookies[COOKIE_ID] as CookieConsentResult);
    }
  }, [cookies]);

  const closeModal = () => {
    onResult(CookieConsentResult.NONE);
    setCookie(COOKIE_ID, CookieConsentResult.NONE, {
      path: '/',
      maxAge: 30 * 60, // save for 30 mins
    });
  };

  const handleOnResult = (result: CookieConsentResult) => {
    onResult(result);
    setCookie(COOKIE_ID, result, {
      path: '/',
      maxAge: 60 * 60 * 24 * 30, // 30 day consent
    });
  };

  // set up an event emitter to announce that the user has closed cookies
  useEffect(() => {
    if (outcome != null) {
      const event = new Event(COOKIE_EVENT);
      document.dispatchEvent(event);
      console.log('dispatch event');
    }
  }, [outcome]);

  if (!outcome) {
    return (
      <CookieConsentComponent onResult={handleOnResult} onClose={closeModal} />
    );
  }
  return null;
};
