import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { BackHeader } from '../../components/back-header';
import confirmModal from '../../components/confirm-modal';
import { ContextItem } from '../../components/context-menu';
import { ChatWrapper } from './chat-wrapper';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { actions } from '../../redux/slices';
import selectors from '../../redux/selectors';
import { usePrevious } from '../../hooks/use-previous';
import StopIcon from '../../assets/icons/stop.svg?react';
import BinIcon from '../../assets/icons/bin.svg?react';
import FolderIcon from '../../assets/icons/folder.svg?react';
import { toast } from 'react-toastify';
import { confirm } from '@law-connect/react-components';

export const LawyerConnection: FC = () => {
  // if is the connection id
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const connection = useAppSelector(
    selectors.lawyerConnection.getConnectionById(id)
  );
  const connectionMessages = useAppSelector(
    selectors.lawyerConnection.getMessagesById(id)
  );
  const prevConnection = usePrevious(connection);
  const isSendingMessage = useAppSelector(
    selectors.lawyerConnection.isSendingMessage()
  );
  const prematter = useAppSelector(
    selectors.prematter.getById(connection?.prematterId)
  );
  const isFetchingMessages = useAppSelector(
    selectors.lawyerConnection.isFetchingMessages()
  );
  const prevIsFetchingMessages = usePrevious(isFetchingMessages);
  const fetchingMessageError = useAppSelector(
    selectors.lawyerConnection.getFetchMessagesError()
  );
  const files = useAppSelector(selectors.lawyerConnection.getFiles(id));
  const isSendingFiles = useAppSelector(
    selectors.lawyerConnection.isSendingFiles()
  );

  const onSendMessage = useCallback(
    (args: { text?: string; files?: File[] }) => {
      if (!isSendingMessage) {
        if (args.files?.length) {
          console.log('send files = ', args.files);
          // we want to send files
          dispatch(
            actions.lawyerConnection.sendFiles({
              id,
              files: args.files,
            })
          );
        }
        if (args.text?.length) {
          // TODO: if files we need to upload them first
          dispatch(
            actions.lawyerConnection.sendMessage({
              id,
              message: args.text,
            })
          );
        }
      }
    },
    [dispatch, id, isSendingMessage]
  );

  const endChat = useCallback(() => {
    // open the link in a new tab
    confirm({
      title: t('connect.end-chat'),
      description: t('connect.end-chat-confirmation'),
      onConfirm: async () => {
        try {
          await dispatch(actions.lawyerConnection.markEnded({ id })).unwrap();
          toast.success(t('connect.end-chat-success'));
        } catch (error) {
          console.error('end chat', error);
          toast.error(t('connect.end-chat-error'));
        }
      },
    });
  }, [dispatch, id, t]);

  const deleteChat = useCallback(async () => {
    confirm({
      title: t('connect.delete-conversation'),
      description: t('connect.delete-conversation-confirmation'),
      onConfirm: async () => {
        try {
          await dispatch(actions.lawyerConnection.markDeleted({ id })).unwrap();
          toast.success(t('connect.delete-conversation-success'));
        } catch (error) {
          console.error('delete chat', error);
          toast.error(t('connect.delete-conversation-error'));
        }
      }
    });
  }, [dispatch, id, t]);

  const backItems: ContextItem[] = useMemo(() => {
    return [
      {
        label: t('connect.end-chat'),
        onClick: endChat,
        icon: <StopIcon />,
        disabled: connection?.endedAt ? true : false,
      },
      {
        label: t('prematter.delete'),
        onClick: deleteChat,
        icon: <BinIcon />,
      },
    ];
  }, [connection?.endedAt, deleteChat, endChat, t]);

  useEffect(() => {
    dispatch(actions.lawyerConnection.fetchById({ id }));
    dispatch(actions.lawyerConnection.fetchFiles({ id }));
    dispatch(actions.prematter.fetch());
  }, [dispatch, id]);

  useEffect(() => {
    if (
      (connection && !prevConnection) ||
      connection?.id !== prevConnection?.id
    ) {
      dispatch(actions.lawyerConnection.fetchMessages({ id }));
      dispatch(
        actions.lawyerConnection.pollMessages({ connectionId: id, init: true })
      );
    }
  }, [connection, dispatch, id, prevConnection]);

  useEffect(() => {
    return () => {
      dispatch(actions.lawyerConnection.stopPollMessages({ connectionId: id }));
    };
  }, [dispatch, id]);

  useEffect(() => {
    // only when we have successfully fetched messages we want to update the read at
    if (
      !isFetchingMessages &&
      prevIsFetchingMessages &&
      !fetchingMessageError
    ) {
      const lastMessage = connectionMessages[connectionMessages.length - 1];
      if (lastMessage?.from === 'lawyer' && !lastMessage.readAt) {
        dispatch(
          actions.lawyerConnection.updateReadAt({
            id,
            messageId: lastMessage?.id,
          })
        );
      }
    }
    // eslint-disable-next-line @stylistic/js/max-len
  }, [
    dispatch,
    id,
    isFetchingMessages,
    prevIsFetchingMessages,
    fetchingMessageError,
    connectionMessages,
  ]);

  useEffect(() => {
    // if connection no longer exists due to delete then we navigate back
    if (!connection && prevConnection) {
      navigate(-1);
    }
  }, [connection, prevConnection, navigate]);

  if (!connection) {
    return null;
  }

  return (
    <div className={style.container}>
      <Header />
      <div className={style.chatContainer}>
        <BackHeader items={backItems} className={style.prematterHeader}>
          <Link to={`/case/${prematter?.id}`} className={style.caseNameWrapper}>
            <div className={style.iconBackground}>
              <FolderIcon className={style.icon} />
            </div>
            <div className={style.caseName}>{prematter?.name}</div>
          </Link>
        </BackHeader>
        <ChatWrapper
          isLoading={false}
          messages={connectionMessages ?? []}
          onSendMessage={onSendMessage}
          connection={connection}
          files={files}
          isSendingFiles={isSendingFiles}
        />
      </div>
      <Footer className={style.anonFooter} />
    </div>
  );
};
