import React, { FC } from 'react';
import {
  LawyerConnection,
  Message,
  MessageType,
  File,
} from '@law-connect/types';
import {
  ChatMessageType,
  ChatMessageWrapper,
  formatLongDateTime,
} from '@law-connect/react-components';
import { PermissionMessage } from './permission-message';
import { ClientInductionMessage } from './client-induction';
import { ChatFile, FileName, FilePreview } from '../chat-files/file';
import style from './style.module.less';

interface MessageWrapperProps {
  message: Message;
  messages: Message[];
  files: File[];
  hoverDelay?: number;
  connection: LawyerConnection;
}

export const MessageWrapper: FC<MessageWrapperProps> = (props) => {
  const { message, messages, connection, files } = props;
  const hoverDelay = props.hoverDelay ?? 200;

  if (
    message.type === MessageType.Permission ||
    message.type === MessageType.ClientIntakeForm
  ) {
    const MessageComponent =
      message.type === MessageType.Permission
        ? PermissionMessage
        : ClientInductionMessage;
    return (
      <MessageComponent
        messages={messages}
        message={message}
        connection={connection}
      />
    );
  } else if (
    message.type === MessageType.Chat ||
    message.type === MessageType.Location ||
    message.type === MessageType.Confirmation
  ) {
    if (message.content) {
      return (
        <ChatMessageWrapper
          messageBody={message.content}
          type={
            message.from === 'lawyer'
              ? ChatMessageType.Receiver
              : ChatMessageType.Sender
          }
          timestamp={
            message.createdAt ? formatLongDateTime(message.createdAt) : null
          }
          hoverDelay={hoverDelay}
          className={message.from === 'lawyer' ? style.receiver : style.sender}
        />
      );
    } else if (message.fileIds?.length) {
      return message.fileIds.map((fileId, index) => {
        const file = files?.find((f) => f.id === fileId);
        return (
          // <ChatFile file={file} key={file?.id ?? index} />;
          <ChatMessageWrapper
            key={file?.id ?? index}
            messageBody={<FileName file={file} />}
            type={
              message.from === 'lawyer'
                ? ChatMessageType.Receiver
                : ChatMessageType.Sender
            }
            timestamp={
              message.createdAt ? formatLongDateTime(message.createdAt) : null
            }
            hoverDelay={hoverDelay}
            externalBody={
              <FilePreview
                file={file}
                showDownload={true}
                isSender={message.from === 'client'}
              />
            }
            className={`${style.messageWrapper} ${
              message.from === 'client'
                ? style.noBottomBorderRadiusSender
                : style.noBottomBorderRadiusReceiver
            }`}
          />
        );
      });
    }
  }
};
