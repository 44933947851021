/* eslint-disable @stylistic/js/max-len */
import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import style from './style.module.less';
import Logo from '../../assets/logo-white.png';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { useLocation } from 'react-router-dom';
import CloseIcon from '../../assets/icons/cross-circle.svg?react';

export const enum AuthModalType {
  AUTH = 'auth',
  BOT_AUTH = 'bot-auth',
}

export interface LoginModalRef {
  closeModal: () => void;
  openModal: () => void;
}

interface Props {
  type?: AuthModalType;
  onClose?: () => void;
  redirectTo?: string;
}

const AuthModal = forwardRef<LoginModalRef | null, Props>((props, ref) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const { redirectTo, type = AuthModalType.AUTH } = props;
  const { login, register, isAuthenticated } = useKindeAuth();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = createRef<HTMLDivElement>();
  const closeModal = useCallback(() => {
    onClose?.();
    setIsOpen(false);
  }, [onClose]);


  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);
  useOnClickOutside(modalRef, closeModal);

  useImperativeHandle(ref, () => ({
    closeModal,
    openModal,
  }));

  useEffect(() => {
    // stop scrolling when modal is isOpen
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const loginRedirect = useCallback(() => {
    login({
      // eslint-disable-next-line camelcase
      app_state: {
        redirectTo: redirectTo || location.state?.from?.pathname || '/account',
      },
    });
  }, [location.state?.from?.pathname, login, redirectTo]);

  const registerRedirect = useCallback(() => {
    register({
      // eslint-disable-next-line camelcase
      app_state: {
        redirectTo: redirectTo || location.state?.from?.pathname || '/account',
      },
    });
  }, [register, redirectTo, location.state?.from?.pathname]);

  const body = useMemo(
    () => (
      <div className={style.modalWrapper}>
        <div className={style.modalBody} ref={modalRef}>
          <div className={style.headerWrapper}>
            <img
              src={`/auth/${
                type === AuthModalType.BOT_AUTH ? 'bot' : 'standard'
              }.png`}
              className={style.authBG}
            />
            <div className={style.closeIconContainer} onClick={closeModal}>
              <CloseIcon className={style.closeIcon}/>
            </div>
            <div className={style.header}>
              <div className={style.logoWrapper}>
                <img src={Logo} className={style.logoImage} />
              </div>
              <div
                className={style.bodyText}
                dangerouslySetInnerHTML={{
                  __html: t('auth.modal.description'),
                }}
              />
            </div>
          </div>
          <div className={style.buttonWrapper}>
            <button className={style.button} onClick={registerRedirect}>
              {t('auth.modal.create-account')}
            </button>
            <button className={style.button} onClick={loginRedirect}>
              {t('auth.modal.sign-in')}
            </button>
          </div>

          <div
            className={style.authText}
            dangerouslySetInnerHTML={{ __html: t('auth.modal.footer') }}
          />
        </div>
      </div>
    ),
    [closeModal, loginRedirect, modalRef, registerRedirect, t, type]
  );

  return createPortal(!isAuthenticated && isOpen ? body : <></>, document.body);
});

export default AuthModal;
